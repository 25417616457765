var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_tax_income") } },
    [
      _c(
        "a-form",
        _vm._b(
          {
            attrs: { form: _vm.form },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.findData.apply(null, arguments)
              }
            }
          },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.pphNumber.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.pphNumber.decorator,
                            expression: "formRules.pphNumber.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.pphNumber.name,
                          placeholder: _vm.$t(
                            _vm.formRules.pphNumber.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.pphType.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.pphType.decorator,
                              expression: "formRules.pphType.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.pphType.name,
                            placeholder: _vm.$t(
                              _vm.formRules.pphType.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingPphType,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getPphType(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataPphType, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.code) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.code) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.branch.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.customerName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCustomerName,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomerName(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCustomerName, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.fullName
                                            ? data.fullName.replace("null", "")
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.fullName
                                          ? data.fullName.replace("null", "")
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.currency.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.currency.decorator,
                              expression: "formRules.currency.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.currency.name,
                            placeholder: _vm.$t(
                              _vm.formRules.currency.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCurrency,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCurrency(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCurrency, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.currencyCode) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(data.currencyCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.date.decorator,
                            expression: "formRules.date.decorator"
                          }
                        ],
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          name: _vm.formRules.date.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateOfPph.label) } },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateOfPph.decorator,
                            expression: "formRules.dateOfPph.decorator"
                          }
                        ],
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          name: _vm.formRules.dateOfPph.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.status.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.status.decorator,
                              expression: "formRules.status.decorator"
                            }
                          ],
                          attrs: {
                            "show-search": "",
                            "allow-clear": "",
                            loading: _vm.loading.status,
                            "option-filter-prop": "children",
                            "filter-option": _vm.useLocalFilter
                          }
                        },
                        _vm._l(_vm.optStatus, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(item.label))]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleReset } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.loadingFind,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$can("create", "input-pph")
                ? _c(
                    "a-col",
                    { attrs: { span: 12, align: "end" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "tax.transaction.tax-income.create" }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columnsTable,
          loading: _vm.loading.loadingFind,
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", { total: _vm.totalData })
            },
            total: _vm.totalData,
            showSizeChanger: true,
            current: _vm.page,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
          },
          size: "small",
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectRow,
            getCheckboxProps: _vm.getTableRowProps
          },
          scroll: { y: 600, x: "calc(100% + 450px)" }
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "isCurrency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "operation",
            fn: function(text, record) {
              return [
                _c(
                  "a-dropdown",
                  { attrs: { trigger: ["click"], placement: "bottomCenter" } },
                  [
                    _c("a-button", {
                      attrs: { icon: "bars" },
                      on: {
                        click: function(e) {
                          return e.preventDefault()
                        }
                      }
                    }),
                    _c(
                      "a-menu",
                      {
                        staticClass: "mt-2",
                        attrs: { slot: "overlay" },
                        slot: "overlay"
                      },
                      [
                        _c(
                          "a-menu-item",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "tax.transaction.tax-income.detail",
                                    params: { id: record.id }
                                  }
                                }
                              },
                              [
                                _c("a-icon", { attrs: { type: "eye" } }),
                                _vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "journal",
            fn: function(text, record) {
              return [
                record.journalNumber
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "generaljournal.detail",
                            params: { id: record.journalId }
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(record.journalNumber) + " ")]
                    )
                  : _c("span", [_vm._v(_vm._s("-"))])
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _vm.$can("cancel", "input-pph")
            ? [
                _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: _vm.$t("pop_confirmation"),
                      "ok-text": _vm.$t("lbl_yes"),
                      "cancel-text": _vm.$t("lbl_no")
                    },
                    on: { confirm: _vm.handleCancel }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "danger",
                          disabled: _vm.selectedRowKeys.length < 1,
                          loading: _vm.loading.cancel
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }