








































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomListCategory,
  DataListAssetBook,
  DataListMasterAsset,
  RequestPostReclass,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import moment from "moment";
import Vue from "vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
export default Vue.extend({
  data() {
    this.getListNewCategory = debounceProcess(this.getListNewCategory, 200);
    this.getListOldCategory = debounceProcess(this.getListOldCategory, 200);
    return {
      DEFAULT_DATE_FORMAT,
      idBook: "" as string,
      dataListBook: [] as DataListAssetBook[],
      dataNewCategory: [] as CustomListCategory[],
      dataOldCategory: [] as CustomListCategory[],
      selectedRowKeys: [] as number[],
      dataSource: [] as DataListMasterAsset[],
      columnsTable: [
        {
          title: "Asset Number",
          dataIndex: "assetNo",
          key: "assetNo",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Acquisition Date",
          dataIndex: "acquisitionDate",
          key: "acquisitionDate",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Reclass",
          dataIndex: "completeReclass",
          key: "completeReclass",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Reclass Date",
          dataIndex: "completeReclassDate",
          key: "completeReclassDate",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
      ],
      visible: false as boolean,
      reclass: false as boolean,
      loadingModal: false as boolean,
      loadingListAssetBook: false as boolean,
      loadingOldCategory: false as boolean,
      loadingNewCategory: false as boolean,
      loading: false as boolean,
      loadingFind: false as boolean,
      totalElements: 0 as number,
      page: 1 as number,
      limit: 10 as number,
      form: this.$form.createForm(this, { name: "Reclass" }),
      formModal: this.$form.createForm(this, { name: "Reclass Modal" }),
      formRulesModal: {
        reclassDate: {
          label: "lbl_reclass_date",
          name: "reclassDate",
          placeholder: "lbl_reclass_date_placeholder",
          decorator: [
            "reclassDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        newCategory: {
          label: "lbl_new_category",
          name: "newCategory",
          placeholder: "lbl_new_category_placeholder",
          decorator: [
            "newCategory",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRules: {
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: ["assetNumber"],
        },
        oldCategory: {
          label: "lbl_old_category",
          name: "oldCategory",
          placeholder: "lbl_old_category_placeholder",
          decorator: [
            "oldCategory",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        reclassDate: {
          label: "lbl_reclass_date",
          name: "reclassDate",
          placeholder: "lbl_reclass_date_placeholder",
          decorator: [
            "reclassDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        reclass: {
          label: "lbl_reclass",
        },
      },
    };
  },
  methods: {
    moment,
    handleBook(idBook) {
      this.idBook = idBook;
      this.getListOldCategory("", idBook);
    },
    handleOpenModal() {
      this.visible = true;
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_AND_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.submitForm();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.submitForm();
    },
    onSelectChange(value) {
      this.selectedRowKeys = value;
    },
    submitFormModal() {
      this.formModal.validateFields((err, res) => {
        if (err) return;
        this.loadingModal = true;
        let tempId = [] as string[];
        this.dataSource.forEach((dataMap, index) => {
          if (this.selectedRowKeys.includes(index)) {
            tempId.push(dataMap.id);
          }
        });

        const payload = {
          assetIds: tempId,
          payload: {
            assetCategoryId: res.newCategory,
            reclassDate: res.reclassDate,
          },
        } as RequestPostReclass;
        assetsServices
          .createReclass(payload)
          .then(() => {
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.formModal.resetFields();
            // this.submitForm()
            this.totalElements = 0;
            this.form.resetFields();
            this.dataSource = [];
            this.visible = false;
          })
          .finally(() => (this.loadingModal = false));
      });
    },
    assignSearch(key, value, and): string {
      if (key === "oldCategory" && value)
        return and + `assetCategory.categoryId~*${value}*`;
      else if (key === "assetNumber" && value) return and + `assetNo~${value}`;
      else if (key === "reclassDate" && value && value.length > 0)
        return (
          and +
          `completeReclassDate>=${moment(value[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}_AND_completeReclassDate<=${moment(value[1])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else return "";
    },
    dynamicSearch(res, paramsSearch): string {
      let search = paramsSearch;
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    submitForm() {
      this.form.validateFields((err, res) => {
        if (err) return;
        let params = {
          page: this.page - 1,
          limit: 10,
          search: `status~RENTED_OR_status~READY`,
          bookId: `${res.book}`,
        } as RequestQueryParamsModel;

        params.search = this.dynamicSearch(res, params.search);

        if (params.search)
          params.search += `_AND_completeReclass~${this.reclass}`;
        else params.search = `completeReclass~${this.reclass}`;

        this.loading = true;
        this.loadingFind = true;
        assetsServices
          .listMasterAsset(params)
          .then(data => {
            this.totalElements = data.totalElements;
            this.dataSource = data.data.map(dataMap => {
              dataMap.acquisitionDate = moment(dataMap.acquisitionDate).format(
                "DD MMM yyyy"
              );
              dataMap.completeReclass = dataMap.completeReclass ? "YES" : "NO";
              return dataMap;
            });
          })
          .finally(() => {
            this.loading = false;
            this.loadingFind = false;
          });
      });
    },
    handleCancel() {
      this.$router.push("/inquiry/find");
    },
    mapIdCategories(idCategories): string[] {
      return idCategories.map(dataMap => {
        let temp = dataMap.split("");
        if (temp.length > 0) {
          temp[0] = temp[0].toUpperCase();
          dataMap = temp.join("");
        }
        return dataMap;
      });
    },
    checkAndReturnString(value): string {
      if (value) return value;
      else return "";
    },
    getListOldCategory(value, idBook) {
      if (!idBook) {
        this.$notification.error({
          message: "Error",
          description: "Select Book First",
        });
      } else {
        let params = {
          page: 0,
          limit: 10,
          search: `book.secureId~*${idBook}*_AND_book.type~commercial_AND_categoryId~*.sgu*`,
        } as RequestQueryParamsModel;
        if (value) params.search += `_AND_categoryId~*${value}*`;
        this.loadingOldCategory = true;
        assetsServices
          .listAssetCategory(params)
          .then(response => {
            let tempDataSegments = [] as CustomListCategory[];
            response.data.forEach((data, index) => {
              let tempSegment = {
                id: data.id,
                accumulatedDepreciationAccountId: `${this.checkAndReturnString(
                  data.accumulatedDepreciationAccount.code
                )}-${data.accumulatedDepreciationAccount.description}`,
                depreciationAccount: `${this.checkAndReturnString(
                  data.depreciationExpenseAccount.code
                )}-${data.depreciationExpenseAccount.description}`,
                first: "",
                second: "",
                idForView: "",
                key: index,
              } as CustomListCategory;

              let idCategories = data.category.id.split(".");

              idCategories = this.mapIdCategories(idCategories);

              tempSegment.idForView = idCategories.join(".");
              tempDataSegments.push(tempSegment);
            });
            this.dataOldCategory = tempDataSegments;
          })
          .finally(() => (this.loadingOldCategory = false));
      }
    },
    getListNewCategory(value) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (value) params.search = `categoryId~*${value}*`;
      this.loadingNewCategory = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach((data, index) => {
            let tempSegment = {
              id: data.id,
              accumulatedDepreciationAccountId: `${
                data.accumulatedDepreciationAccount.code
                  ? data.accumulatedDepreciationAccount.code
                  : ""
              }-${data.accumulatedDepreciationAccount.description}`,
              depreciationAccount: `${
                data.depreciationExpenseAccount.code
                  ? data.depreciationExpenseAccount.code
                  : ""
              }-${data.depreciationExpenseAccount.description}`,
              first: "",
              second: "",
              idForView: "",
              key: index,
            } as CustomListCategory;

            let idCategories = data.category.id.split(".");

            idCategories = this.mapIdCategories(idCategories);

            tempSegment.idForView = idCategories.join(".");
            tempDataSegments.push(tempSegment);
          });
          this.dataNewCategory = tempDataSegments;
        })
        .finally(() => (this.loadingNewCategory = false));
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListAssetBook("");
    this.getListNewCategory("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
