
















































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { Row, useLocalFilter, useTax } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListInputPph } from "@/models/interface/invoice.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  DataMasterCurrency,
  DataTax,
} from "@/models/interface/settings.interface";
import { contactServices } from "@/services/contact.service";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class ListInputPph extends Mixins(MNotificationVue) {
  moment = moment;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useLocalFilter = useLocalFilter;

  form!: WrappedFormUtils;
  page = 1;
  limit = 10;
  params: RequestQueryParamsModel = {};
  totalData = 0;
  loading = {
    status: false,
    loadingFind: false,
    loadingBastNumber: false,
    download: false,
    loadingCustomerName: false,
    loadingBranch: false,
    loadingDownload: false,
    loadingPphType: false,
    loadingCurrency: false,
    cancel: false,
  };

  dataSource: Row<DataResponseListInputPph, string>[] = [];
  selectedRowKeys: Array<string> = [];
  columnsTable = [
    {
      title: this.$t("lbl_pph_number"),
      dataIndex: "pphNumber",
      key: "pphNumber",
      width: "250px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_pph_type"),
      dataIndex: "pphType",
      key: "pphType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: "450px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_pph_amount_total"),
      dataIndex: "pphAmountTotal",
      key: "pphAmountTotal",
      width: "150px",
      align: "right",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      align: "center",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_pph_date"),
      dataIndex: "datePPHForm",
      key: "datePPHForm",
      align: "center",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumber",
      key: "journalNumber",
      scopedSlots: { customRender: "journal" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: "100px",
    },
    {
      title: this.$t("lbl_action"),
      key: "operation",
      scopedSlots: { customRender: "operation" },
      align: "center",
    },
  ];

  formRules = {
    status: {
      label: "lbl_status",
      decorator: ["status"],
    },
    dateOfPph: {
      label: "lbl_date_pph",
      name: "dateOfPph",
      placeholder: "lbl_date_pph_placeholder",
      decorator: [
        "dateOfPph",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "lbl_date",
      name: "date",
      placeholder: "lbl_date_placeholder",
      decorator: [
        "date",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    pphNumber: {
      label: "lbl_pph_number",
      name: "pphNumber",
      placeholder: "lbl_pph_number_placeholder",
      decorator: [
        "pphNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    pphType: {
      label: "lbl_pph_type",
      name: "pphType",
      placeholder: "lbl_pph_type_placeholder",
      decorator: [
        "pphType",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  dataCustomerName: ContactData[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  dataPphType: DataTax[] = [];
  dataCurrency: DataMasterCurrency[] = [];
  optStatus: Array<Option> = [];

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "ListInputPPh" });
  }

  created() {
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getPphType = debounceProcess(this.getPphType, 400);
    this.getCurrency = debounceProcess(this.getCurrency, 400);

    this.getCustomerName("");
    this.getBranch("");
    this.getPphType("");
    this.getCurrency("");
    this.findStatus();
  }

  get formItemLayout() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };
  }

  findStatus(): void {
    const { findTaxIncomeStatus } = useTax();
    this.loading.status = true;
    findTaxIncomeStatus()
      .then(response => {
        this.optStatus = response.map<Option>(item => ({
          label: item.value,
          key: item.id,
          value: item.value,
        }));
      })
      .finally(() => {
        this.loading.status = false;
      });
  }

  getPphType(valueSearch: string) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `taxType~INCOME_TAX_RECEIVABLES`,
    };
    if (valueSearch)
      params.search = `code~*${valueSearch}*_OR_description~*${valueSearch}*_AND_taxType~INCOME_TAX`;

    this.loading.loadingPphType = true;
    settingsServices
      .listOfTax(params, "")
      .then(response => {
        this.dataPphType = response.data;
      })
      .finally(() => (this.loading.loadingPphType = false));
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }
  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }
  getCustomerName(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "customer~true_AND_active~true",
    };
    if (valueSearch)
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    this.loading.loadingCustomerName = true;
    contactServices
      .listContactData(params)
      .then(response => (this.dataCustomerName = response.data))
      .finally(() => (this.loading.loadingCustomerName = false));
  }
  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }
  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(
      `,${
        this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
      }`
    );
    params += this.checkValue(
      `,${this.dataCustomerName
        .find(dataFind => dataFind.id === res["customerName"])
        ?.fullName?.replaceAll(",", " ")}`
    );
    params += this.checkValue(`,${res["internalContractNo"]}`);

    return params;
  }
  assignSearch(key, value, and): string {
    if (key === "status" && value) return and + `status~${value}`;
    else if (key === "branch" && value) return and + `branch.secureId~${value}`;
    else if (key === "pphType" && value)
      return and + `accountingTax.secureId~${value}`;
    else if (key === "customerName" && value)
      return and + `customer.secureId~${value}`;
    else if (key === "currency" && value)
      return and + `currency.secureId~${value}`;
    else if (key === "pphNumber" && value) return and + `pphNumber~*${value}*`;
    else if (key === "date" && value && value.length > 0)
      return (
        and +
        `date>=${moment(value[0])
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_date<=${moment(value[1])
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "dateOfPph" && value && value.length > 0)
      return (
        and +
        `datePPHForm>=${moment(value[0])
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_datePPHForm<=${moment(value[1])
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else return "";
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  handleReset() {
    this.form.resetFields();
  }

  findData(): void {
    this.form.validateFields((err, values) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
        sorts: "createdDate:desc",
      };
      params.search = this.dynamicSearch(values);
      this.loading.loadingFind = true;
      invoiceServices
        .getListInputPph(params)
        .then(response => {
          this.totalData = response.totalElements;
          this.dataSource = response.data.map(item => ({
            ...item,
            key: item.id,
          }));
        })
        .finally(() => (this.loading.loadingFind = false));
    });
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.page = pagination.current;
    if (this.limit !== pagination.pageSize) {
      this.page = 1;
    }

    this.limit = pagination.pageSize;
    this.findData();
  }

  onSelectRow(keys: Array<string>): void {
    this.selectedRowKeys = keys;
  }

  getTableRowProps(r: Row<DataResponseListInputPph, string>) {
    return {
      props: {
        disabled: r.status === "Cancelled",
      },
    };
  }

  async handleCancel(): Promise<void> {
    try {
      const { cancelPphByIds } = useTax();
      const keys = [...this.selectedRowKeys];
      this.loading.cancel = true;
      const response = await cancelPphByIds(keys);
      this.showNotifSuccess("notif_cancel_success", {
        documentNumber: response.map<string>(item => item.pphNumber).join(", "),
      });
      this.selectedRowKeys = [];

      // reset data table
      this.page = 1;
      this.findData();
    } catch (error) {
      this.showNotifError("notif_cancel_fail");
    } finally {
      this.loading.cancel = false;
    }
  }
}
